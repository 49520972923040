<template>
  <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh" style="height: 100%; overflow: hidden auto;">
    <template v-if="detail">
      <div class="padding-08">
        <div class="padding-08 bold fs-huge fc-t">{{ detail.proName }}</div>
        <div class="padding-08 fc-c">{{ detail.remark || '暂无描述' }}</div>
      </div>
      <van-cell-group title="项目概况">
        <van-cell title="项目进度">
          <div class="h c">
            <van-progress color="#607ae3" :percentage="detail.progress*100" :show-pivot="false" class="flex" />
            <span class="no-flex fs-small gap-l-small">{{Math.round(detail.progress*10000) / 100}}%</span>
          </div>
        </van-cell>
        <van-cell title="项目状态">
          <van-tag v-if="detail.status == 'WAITTING'">待开始</van-tag>
          <van-tag type="primary" v-else-if="detail.status == 'RUNING'">进行中</van-tag>
          <van-tag type="success" v-else-if="detail.status == 'FINISH'">已完成</van-tag>
          <van-tag type="danger" v-else-if="detail.status == 'CANCEL'">已取消</van-tag>
        </van-cell>
        <van-cell title="立项时间">
          <span class="fs-small gap-l-small">{{detail.proCreateAt}}</span>
        </van-cell>
        <van-cell title="项目成员">
          <template #label>
            <staff-wall type="project" :target-id="projectId" show-total class="gap-10" />
          </template>
        </van-cell>
      </van-cell-group>

      <van-cell-group title="项目阶段">
        <template v-if="stageList.length">
          <van-cell v-for="(item, index) in stageList" :key="index" is-link @click="toStage(item)">
            <template #title>
              <span>{{ item.stageName }}</span>
            </template>
            <van-tag v-if="item.status == 'WAITTING'">待开始</van-tag>
            <van-tag type="primary" v-else-if="item.status == 'RUNING'">进行中</van-tag>
            <van-tag type="success" v-else-if="item.status == 'FINISH'">已完成</van-tag>
            <van-tag type="danger" v-else-if="teim.status == 'CANCEL'">已取消</van-tag>
            <span class="fs-small gap-l-1x">进度: {{item.progressStr}}</span>
          </van-cell>
        </template>
        <van-empty v-else description="暂无阶段信息" />
      </van-cell-group>
      <van-cell-group title="项目详情">
        <van-cell title="项目编码">{{ detail.proCode }}</van-cell>
        <van-cell title="项目类型">{{ detail.proTypeName }}</van-cell>
        <van-cell title="注册类型">{{ detail.regTypeName }}</van-cell>
        <van-cell title="剂型">{{ detail.dosageFormName }}</van-cell>
        <van-cell title="项目分类">{{ detail.proClassificationName }}</van-cell>
        <van-cell title="规格信息">
          <template #label>{{ detail.specInfo }}</template>
        </van-cell>
        <van-cell title="治疗领域">
          <template #label>{{ detail.therapeuticArea }}</template>
        </van-cell>
        <van-cell title="适应症">
          <template #label>{{ detail.adaptationDisease }}</template>
        </van-cell>
      </van-cell-group>
    </template>
  </van-pull-refresh>
</template>

<script>
import { getStage } from "@/api/project/stage";
import { getProjectById } from "@/api/project/index";
import StaffWall from "../assembly/wall";
export default {
  components: { StaffWall },
  data() {
    return {
      detail: null,
      stageList: [],
      projectId: null,
      projectName: null,
      progress: null,
      isRefreshing: false,
      projectTaskStatusEnum: {
        WAITTING: { id: 0, key: "WAITTING", name: "待开始", type: "info" },
        RUNING: { id: 1, key: "RUNING", name: "进行中", type: "primary" },
        FINISH: { id: 2, key: "FINISH", name: "已完成", type: "success" },
        CANCEL: { id: 3, key: "CANCEL", name: "已取消", type: "danger" },
      },
      typeEnum: {
        info: "fc-g",
        primary: "fc-p",
        success: "fc-s",
        danger: "fc-e",
      },
    };
  },
  mounted() {
    this.projectId = this.$route.params.id;
    if (this.projectId) {
      this.getProjectDetail();
      this.getStageList();
    }
  },
  methods: {
    onRefresh() {
      // 下拉刷新数据
      this.stageList = [];
      this.getProjectDetail();
      this.getStageList();
      this.isRefreshing = false;
    },
    getProjectDetail() {
      getProjectById(this.projectId).then((res) => {
        this.detail = res;
      });
    },
    getStageList() {
      getStage({ proId: this.projectId }).then((res) => {
        this.stageList = res || [];
      });
    },
    toStage(item) {
      this.$router.push({
        path: "/project/stage/detail/" + item.id,
      });
    },
  },
};
</script>