<template>
  <div class="app-assembly-staffs" :class="[`at-${position}`]">
    <div v-for="staff in staffs" :key="staff.id" class="staff-item" @click.stop>
      <div class="inner" :style="{backgroundImage: `url(${staff.avatar})`}">
        <template v-if="!staff.avatar">{{staff.userName.substr(-1)}}</template>
      </div>
      <div class="details" v-if="!unfloding">
        <div class="bold fs-large">{{staff.userName}}</div>
        <div class="gap-small lh-120 op-60" v-if="staff.memberType === 'MANAGER'">管理人员</div>
      </div>
    </div>
    <div class="staff-count" v-if="showTotal">
      共
      <b>{{staffTotal||total}}</b>&nbsp;人
    </div>
  </div>
</template>

<script>
import { getProjectMembers, getTaskMembers } from "@/api/project";

export default {
  props: {
    position: {
      type: String,
      default: "bottom",
    },
    type: String,
    targetId: String | Number,
    max: {
      type: Number,
      default: 5,
    },
    total: Number,
    showTotal: Boolean,
    unfloding: Boolean,
  },
  data() {
    return {
      loading: false,
      staffs: [],
      staffTotal: 0,
    };
  },
  watch: {
    type: "load",
    targetId: "load",
  },
  methods: {
    load() {
      if (this.type && this.targetId) {
        this.loading = true;
        let fn,
          params = {
            size: this.max > 0 ? this.max : 5,
          };
        switch (this.type) {
          case "project":
            fn = getProjectMembers;
            params.proId = this.targetId;
            fn(params).then((res) => {
              this.staffs = res.content || [];
              this.staffTotal = res.totalElements;
              this.$emit("update:total", res.totalElements);
              this.loading = false;
            });
            break;
          case "task":
            fn = getTaskMembers;
            params.taskId = this.targetId;
            fn(params).then((res) => {
              this.staffs = res || [];
              this.staffTotal = res.length || 0;
              this.$emit("update:total", res.length || 0);
              this.loading = false;
            });
            break;
        }
        // fn(params).then(res => {
        //   this.staffs = res.content || [];
        //   this.staffTotal = res.totalElements;
        //   this.$emit("update:total", res.totalElements);
        //   this.loading = false;
        // });
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
.app-assembly-staffs {
  display: flex;
  .staff-item {
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: -8px;
    > .inner {
      position: relative;
      z-index: 2;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--color-primary-dark-3);
      border: var(--border-color-extra-light) solid 1px;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      text-align: center;
      color: var(--color-text-light);
      line-height: 34px;
      font-weight: bold;
    }
    > .details {
      position: absolute;
      top: 18px;
      left: 18px;
      border-radius: 20px;
      background-color: var(--border-color-extra-light);
      overflow: hidden;
      transform: scale(0);
      transform-origin: 0 0;
      transition: all 0.3s;
      padding: 10px 20px 10px 20px;
      white-space: nowrap;
      pointer-events: none;
    }

    &:hover {
      z-index: 2;
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));

      > .details {
        transform: scale(1);
      }
    }

    &:last-child {
      margin-right: 20px;
    }

    ~ .staff-count {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .staff-count {
    line-height: 36px;
    color: var(--color-text-regular);
    opacity: 0.8;
    z-index: 1;
  }

  &.at-top {
    .staff-item {
      > .details {
        top: auto;
        bottom: 18px;
        transform-origin: 0 100%;
      }
    }
  }
}
</style>